/* #### Generated By: http://www.cufon/fonts.com #### */

@font-face {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: local("Nunito Regular"),
    url("./../fonts/Nunito-Regular.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: local("Nunito Light"), url("./../fonts/Nunito-Light.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: local("Nunito SemiBold"),
    url("./../fonts/Nunito-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Nunito";
  font-display: swap;
  font-style: normal;
  font-weight: 700;
  src: local("Nunito Bold"), url("./../fonts/Nunito-Bold.woff") format("woff");
}
